<script lang="ts" setup>
//#region  Import's

import { onMounted, ref } from 'vue';

// Service / Store
import { useStepVistoria } from '@/stores/stepVistoria';

// Component
import HeaderProgress from '@/shared/component/header/header-progress.vue';
import IconArrowLeft from '@/shared/icons/IconArrowLeft.vue';
import Loading from '@/shared/component/show-loading.vue';
import VistoriaAssinatura from './component/VistoriaAssinatura.vue';
import VistoriaContrato from './component/VistoriaContrato.vue';
import VistoriaDadosCnh from './component/VistoriaDadosCnh.vue';
import VistoriaDadosEndereco from './component/VistoriaDadosEndereco.vue';
import VistoriaDadosFotoVideo from './component/VistoriaDadosFotoVideo.vue';
import VistoriaDadosImplementoAgregado from './component/dadosImplementoAgregado/VistoriaDadosImplementoAgregado.vue';
import VistoriaDadosPessoais from './component/VistoriaDadosPessoais.vue';
import VistoriaDadosVeiculo from './component/VistoriaDadosVeiculo.vue';
import VistoriaDadosFinaceiros from './component/VistoriaDadosFinaceiros.vue';
import Warning from '@/shared/component/notify-warning.vue';

// Other
import {
  TIPO_ETAPA_ASSINATURA,
  TIPO_ETAPA_DADOS_AGREGADO,
  TIPO_ETAPA_DADOS_CNH,
  TIPO_ETAPA_DADOS_ENDERECO,
  TIPO_ETAPA_DADOS_IMPLEMENTO,
  TIPO_ETAPA_DADOS_PESSOAIS,
  TIPO_ETAPA_DADOS_VEICULO,
  TIPO_ETAPA_DADOS_FINANCEIROS,
} from '@/global';

//#endregion

const vistoriaStore = useStepVistoria();

const loading = ref(true);
const failGetVistoria = ref(false);

/**
 * Tempo para atualizar front
 */
const timeRefresh = () => {
  setTimeout(() => {
    loading.value = false;
  }, 300);
};

onMounted(() => {
  timeRefresh();
});
</script>

<template>
  <!-- Loading -->
  <Loading :show="loading" />

  <!-- Mensagem de Erro -->
  <Transition name="fade">
    <Warning
      v-if="failGetVistoria"
      message="Ops! Não foi possível localizar a sua vistoria."
      subMessage="Verifique se o link está correto!"
    />
  </Transition>

  <div
    class="vistoria"
    v-if="!loading && !failGetVistoria && vistoriaStore.currentVistoriaEtapa"
  >
    <HeaderProgress />

    <div class="vistoria__content">
      <VistoriaDadosFotoVideo
        v-if="
          vistoriaStore.currentVistoriaEtapa?.fotoVideo &&
          vistoriaStore.currentVistoriaEtapa?.tipo !== TIPO_ETAPA_ASSINATURA
        "
      />

      <VistoriaContrato v-if="vistoriaStore.currentVistoriaEtapa?.contrato" />

      <VistoriaAssinatura
        v-if="
          vistoriaStore.currentVistoriaEtapa?.tipo === TIPO_ETAPA_ASSINATURA
        "
      />

      <VistoriaDadosPessoais
        v-if="
          vistoriaStore.currentVistoriaEtapa?.tipo === TIPO_ETAPA_DADOS_PESSOAIS
        "
      />

      <VistoriaDadosEndereco
        v-if="
          vistoriaStore.currentVistoriaEtapa?.tipo === TIPO_ETAPA_DADOS_ENDERECO
        "
      />

      <VistoriaDadosCnh
        v-if="vistoriaStore.currentVistoriaEtapa?.tipo === TIPO_ETAPA_DADOS_CNH"
      />

      <VistoriaDadosVeiculo
        v-if="
          vistoriaStore.currentVistoriaEtapa?.tipo === TIPO_ETAPA_DADOS_VEICULO
        "
      />

      <VistoriaDadosFinaceiros
        v-if="
          vistoriaStore.currentVistoriaEtapa?.tipo ===
          TIPO_ETAPA_DADOS_FINANCEIROS
        "
      />

      <VistoriaDadosImplementoAgregado
        v-if="
          vistoriaStore.currentVistoriaEtapa?.tipo ===
            TIPO_ETAPA_DADOS_IMPLEMENTO ||
          vistoriaStore.currentVistoriaEtapa?.tipo === TIPO_ETAPA_DADOS_AGREGADO
        "
      />
    </div>

    <div class="container vistoria__action">
      <div>
        <button
          v-if="vistoriaStore.currentStep !== 1"
          class="btn btn-link"
          @click="vistoriaStore.previousVistoriaEtapa()"
        >
          <IconArrowLeft />
        </button>
      </div>
      <div>
        <button class="btn btn-link" @click="vistoriaStore.nextVistoriaEtapa()">
          Pular Etapa
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.vistoria {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__content {
    flex: 1;
  }

  &__action {
    display: flex;
    justify-content: space-between;

    .btn.btn-link {
      font-size: 0.6rem;
    }

    svg {
      width: 1rem;
      height: 1rem;
      display: block;
    }
  }
}
</style>
