<script lang="ts" setup>
import IconVideoCamera from '@/shared/icons/IconVideoCamera.vue';
import { goTakeVideo } from '@/shared/utils/routeNavigate';
import { useStepVistoria } from '@/stores/stepVistoria';
import { TIPO_ETAPA_VIDEO } from '@/global';

const vistoriaStore = useStepVistoria();
</script>

<template>
  <div
    class="card buttonTakeVideo"
    role="button"
    @click="goTakeVideo()"
    v-if="vistoriaStore.currentVistoriaEtapa?.tipo === TIPO_ETAPA_VIDEO"
  >
    <span>Gravar Vídeo</span>
    <IconVideoCamera />
  </div>
</template>

<style lang="scss">
.buttonTakeVideo {
  cursor: pointer;
  margin-top: 0.8rem;
  padding: 1rem;
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  color: var(--primary);

  svg {
    display: block;
    width: 2rem;
    height: 2rem;
  }
}
</style>
