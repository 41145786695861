import Dexie, { type Table } from 'dexie';

import type { Files } from '@/model/files.interface';
import type { UserPermissions } from '@/model/permissions-user.interface';
import type { Vistoria } from '@/model/vistoria.interface';
import type { VistoriaEtapas } from './../model/vistoria-etapas.interface';

class MySubClassedDexie extends Dexie {
  files!: Table<Files>;
  vistoria!: Table<Vistoria>;
  vistoriaEtapas!: Table<VistoriaEtapas>;
  userPermissions!: Table<UserPermissions>;

  constructor() {
    super('bp-vistoria');

    this.version(2).stores({
      files: 'id',
      vistoria: 'idProposta, chave',
      vistoriaEtapas: '[idEtapa+chave], idEtapa, chave',
      userPermissions: '++id',
    });
  }
}

export const db = new MySubClassedDexie();

export async function deleteDatabase(chave: string) {
  await db.vistoria.where('chave').equals(chave).delete();

  const vistoriaEtapas = await db.vistoriaEtapas
    .where('chave')
    .equals(chave)
    .toArray();

  for (let index = 0; index < vistoriaEtapas.length; index++) {
    const element = vistoriaEtapas[index];

    await db.vistoriaEtapas
      .where('[idEtapa+chave]')
      .equals([element.idEtapa, element.chave])
      .delete();
  }
}
