import { getDataVistoriaEtapasIndexedDB } from '../vistoria-etapas';
import { getDataVistoriaIndexedDB } from '../vistoria';
import { getsaveUserPermissionsIndexedDB } from '../permissions';
import { useStepVistoria } from '@/stores/stepVistoria';

/**
 * Atualiza a Store da Vistoria
 * @param chave da proposta/vistoria
 */
export async function updateVistoriaStore(chave: string) {
  const vistoriaStore = useStepVistoria();

  const vistoria = await getDataVistoriaIndexedDB(chave);

  if (vistoria) {
    vistoriaStore.setVistoria(vistoria);
  }
}

/**
 * Atualiza a Store das Etapas da Vistoria
 * @param chave da proposta/vistoria
 */
export async function updateVistoriaEtapasStore(chave: string) {
  const vistoriaStore = useStepVistoria();

  const vistoriaEtapas = await getDataVistoriaEtapasIndexedDB(chave);

  if (vistoriaEtapas) {
    vistoriaStore.setVistoriaEtapas(vistoriaEtapas);
  }
}

/**
 * Atualiza a Store User Permissions
 */
export async function updateUserPermissionsStore() {
  const vistoriaStore = useStepVistoria();

  const userPermissions = await getsaveUserPermissionsIndexedDB();

  if (userPermissions) {
    vistoriaStore.setUserPermissions(userPermissions);
  }
}

/**
 * Atualiza a variável que defini se a vistoria esta aberta ou não
 */
export function updateVistoriaAbertaStore(value: boolean){
  const vistoriaStore = useStepVistoria();
  vistoriaStore.setVistoriaAberta(value)
}
