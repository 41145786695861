<template>
  <div class="footer-app">
    <!-- <span class="texto">Baixe o App da Bem Protege</span>
    <div class="footer-app__content">
      <a href="https://onelink.to/ntybhj" target="_blank">
        <img loading="eager" src="@/assets/img/google_play.png" />
        <img loading="eager" src="@/assets/img/app_store.jpg" />
      </a>
    </div> -->
  </div>
</template>

<style lang="scss">
.footer-app {
  width: 100%;
  margin: 0.8rem 0;

  span {
    text-align: center;
    display: block;
    margin-bottom: 0.4rem;
  }

  &__content {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 1rem;

    img {
      max-width: 6rem;
    }
  }
}
</style>
