<script setup lang="ts">
//#region Import's

import { onMounted, onUnmounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import dayjs from 'dayjs';

// Model
import type { Vistoria } from '@/model/vistoria.interface';
import type { VistoriaEtapas } from '@/model/vistoria-etapas.interface';

// Service
import {
  getFileAndObservation,
  getObservation,
} from '@/service/vistoria-files';
import { getDadosVistoria, getVistoria } from '@/service/start';
import {
  getListEtapasPendentes,
  insertEtapaFotoVideoAdicional,
} from '@/service/vistoria-etapas';
import { useStepVistoria } from '@/stores/stepVistoria';

// Component
import ButtonTakePhoto2 from '@/views/Vistoria/component/shared/buttonTakePhoto2.vue';
import ButtonTakeVideo2 from '@/views/Vistoria/component/shared/buttonTakeVideo2.vue';
import FooterSync from '@/shared/component/footer-sync.vue';
import HeaderSimple from '@/shared/component/header/header-simple.vue';
import IconArrowPathVue from '@/shared/icons/IconArrowPath.vue';
import IconCloud from '@/shared/icons/IconCloud.vue';
import IconExclamation from '@/shared/icons/IconExclamation.vue';
import ItemResumoDados from './ItemResumoDados.vue';
import ItemResumoFile from './ItemResumoFile.vue';
import ItemResumoTermo from './ItemResumoTermo.vue';
import Loading from '@/shared/component/show-loading.vue';
import ModalNotify from '@/shared/component/modal-notify.vue';
import ModalQuestion from '@/shared/component/modal-question.vue';

// Other
import {
  ID_MODELO_VIDEO_ADICIONAL,
  ROTA_CONFIRMAR_PENDENCIA,
  STATUS_PROPOSTA_AGUARDANDO_APROVACAO,
  TIPO_ETAPA_ASSINATURA,
  TIPO_ETAPA_DADOS_AGREGADO,
  TIPO_ETAPA_DADOS_CNH,
  TIPO_ETAPA_DADOS_ENDERECO,
  TIPO_ETAPA_DADOS_IMPLEMENTO,
  TIPO_ETAPA_DADOS_PESSOAIS,
  TIPO_ETAPA_DADOS_VEICULO,
  TIPO_ETAPA_DADOS_FINANCEIROS,
  TIPO_ETAPA_FOTO,
  TIPO_ETAPA_TERMO,
  TIPO_ETAPA_VIDEO,
  PESSOA_FISICA,
} from '@/global';
import {
  goEnvioVistoria,
  goHome,
  goResultado,
  goTakeVideo,
  goVistoria,
} from '@/shared/utils/routeNavigate';
import { checaValidadeVistoriaAPI } from '@/service/vistoria';

//#endregion

const route = useRoute();
const router = useRouter();
const vistoriaStore = useStepVistoria();

const vistoria = ref<Vistoria>();
const loading = ref(true);
const etapas = ref<VistoriaEtapas[]>();
const countItemSync = ref<number>(vistoriaStore.countPrepared);

const showModalInfoSync = ref<boolean>(false);
const showModalInfoSynced = ref<boolean>(false);
const showModalQuestionSync = ref<boolean>(false);
const userMediaPermission = ref<boolean>(
  vistoriaStore.userPermissions?.userMedia || false,
);

/**
 * Recupera os dados do Resumo da Vistoria
 */
const getData = async () => {
  etapas.value = vistoriaStore.vistoriaEtpas;

  if (etapas.value) {
    for (let index = 0; index < etapas.value.length; index++) {
      const element = etapas.value[index];
      const idEtapa = element.idEtapa;

      if ([TIPO_ETAPA_FOTO, TIPO_ETAPA_ASSINATURA].includes(element.tipo)) {
        const photo = await getFileAndObservation(idEtapa);

        if (photo) {
          element.resumo = {
            fileContent: photo?.fileContent,
            type: photo?.type,
          };
          element.fotoVideo!.observacaoVistoria = photo?.observacoes;
        }
      }

      if (element.tipo === TIPO_ETAPA_VIDEO) {
        const observacoesVistoria = await getObservation(idEtapa);

        if (observacoesVistoria) {
          element.fotoVideo!.observacaoVistoria =
            observacoesVistoria?.observacoes;

          element.resumo = {
            fileContent: 'VIDEO',
          };
        }
      }
    }
  }

  // Tempo para atualizar front
  setTimeout(() => {
    loading.value = false;
  }, 500);
};

/**
 * Refazer a foto/video
 * @param idVistoriaEtapa id da etapa da vistoria
 */
const retry = (idVistoriaEtapa: string) => {
  vistoriaStore.setCurrentVistoriaEtapaRetry(idVistoriaEtapa);
  goVistoria();
};

/**
 * Navega para a rota de Envio da Vistoria
 */
const goEnvio = async () => {
  const list = await getListEtapasPendentes(vistoria.value!.chave);

  if (list.length > 0) {
    router.push({ name: ROTA_CONFIRMAR_PENDENCIA });
    return;
  }

  goEnvioVistoria();
};

/**
 * Adicona Video Adicional
 */
const addVideoAdicional = async () => {
  const idEtapa = await insertEtapaFotoVideoAdicional(
    vistoriaStore.vistoria!.chave,
    ID_MODELO_VIDEO_ADICIONAL,
  );
  vistoriaStore.setCurrentVistoriaEtapaRetry(idEtapa);
  goTakeVideo();
};

/**
 * Exibe/Oculta modal
 */
const showHideModalSync = () => {
  showModalInfoSync.value = !showModalInfoSync.value;
};

/**
 * Exibe/Oculta modal
 */
const showHideModalSynced = () => {
  showModalInfoSynced.value = !showModalInfoSynced.value;
};

/**
 * Exibe/Oculta modal
 */
const showHideModalQuestionSync = () => {
  showModalQuestionSync.value = !showModalQuestionSync.value;
};

/**
 * Recarregar os dados
 */
const startReloadData = async () => {
  if (countItemSync.value > 0) {
    showModalQuestionSync.value = true;
    return;
  }

  await reloadData();
};

/**
 * Recarregar os dados da API
 */
const reloadData = async () => {
  if (showModalQuestionSync.value) {
    showHideModalQuestionSync();
  }

  countItemSync.value = 0;
  loading.value = true;

  await getVistoria(vistoriaStore.vistoria!.chave);
  vistoriaStore.setPermissions(true);

  await getData();
};

/**
 * Verifica se a etapa é de Dados
 * @param tipo da etapa
 */
const isResumo = (tipo: any) => {
  const ETAPAS_DADOS = [
    TIPO_ETAPA_DADOS_PESSOAIS,
    TIPO_ETAPA_DADOS_ENDERECO,
    TIPO_ETAPA_DADOS_CNH,
    TIPO_ETAPA_DADOS_VEICULO,
    TIPO_ETAPA_DADOS_FINANCEIROS,
    TIPO_ETAPA_DADOS_IMPLEMENTO,
    TIPO_ETAPA_DADOS_AGREGADO,
  ];

  return ETAPAS_DADOS.some((ETAPAS_DADOS) => ETAPAS_DADOS === tipo);
};

/**
 * Verifica se a etapa é de Arquivos
 * @param tipo da etapa
 */
const isFoto = (tipo: any) => {
  const ETAPAS_ARQUIVOS = [
    TIPO_ETAPA_FOTO,
    TIPO_ETAPA_VIDEO,
    TIPO_ETAPA_ASSINATURA,
  ];

  return ETAPAS_ARQUIVOS.some((ETAPAS_ARQUIVOS) => ETAPAS_ARQUIVOS === tipo);
};

vistoriaStore.$subscribe(() => {
  vistoria.value = vistoriaStore!.vistoria;
});

const checaValidateVistoria = async (chave: string) => {
  try {
    const vistoriaValida = await checaValidadeVistoriaAPI(chave as string);
    if (!vistoriaValida) {
      goHome(chave);
    }
  } catch (error) {
    goHome(chave);
  }
};

onMounted(async () => {
  vistoria.value = vistoriaStore!.vistoria;

  if (!vistoria.value) {
    const { chave } = route.params;

    await checaValidateVistoria(chave as string);

    if (chave) {
      await getDadosVistoria(chave as string);
      vistoriaStore.setPermissions(true);

      const idStatus = vistoriaStore.vistoria?.id_Status;

      if (idStatus === STATUS_PROPOSTA_AGUARDANDO_APROVACAO) {
        goResultado();
        return;
      }
    }
  }

  await getData();
});

// 22/06 - Melhorias na performance
onUnmounted(() => {
  etapas.value?.forEach((element) => {
    const fileContent = element.resumo?.fileContent;
    if (fileContent && fileContent !== 'VIDEO') {
      URL.revokeObjectURL(fileContent);
    }
  });
});
</script>

<template>
  <!-- Loading -->
  <Loading :show="loading" />

  <div class="resumo" v-if="!loading">
    <HeaderSimple title="Vistoria" />

    <!-- Modal - Etapa aguardando sicronização -->
    <ModalNotify
      title="Aguardando envio..."
      message="Aguardamos o envio desta etapa para análise"
      :show="showModalInfoSync"
      @close="showHideModalSync()"
    />

    <!-- Modal - Etapa sicronizada -->
    <ModalNotify
      title="Etapa enviada!"
      message="Aguardamos o envio das etapas restantes para análise da sua vistoria"
      :show="showModalInfoSynced"
      @close="showHideModalSynced()"
    />

    <!-- Modal - Reload Vistoria -->
    <ModalQuestion
      title="Atenção!"
      message="Há etapas que já foram concluídas e ainda não foram enviadas. Se você continuar, perderá os dados destas etapas. Deseja continuar? "
      :show="showModalQuestionSync"
      @confirm="reloadData()"
      @cancel="showHideModalQuestionSync()"
    />

    <div class="container resumo__container" v-if="!loading">
      <!-- Seção: Dados da Vistoria -->
      <div class="section resumo__container__title-dados-proposta">
        <span>Dados da Vistoria</span>
        <IconArrowPathVue role="button" @click="startReloadData()" />
      </div>

      <!-- Dados da Vistoria -->
      <div class="resumo__container__dados-proposta">
        <span>
          Nome Completo:
          {{
            vistoria!.dadosPessoais
              ? vistoria!.dadosPessoais.nome
              : vistoria!.nome
          }}</span
        >
        <span>Proposta: {{ vistoria!.idProposta }}</span>
        <span v-if="vistoria?.placa">Placa: {{ vistoria?.placa }}</span>
        <span v-if="!vistoria?.placa && vistoria?.chassi"
          >Chassi: {{ vistoria?.chassi }}</span
        >
        <span v-if="vistoria?.veiculoMarca"
          >Marca: {{ vistoria?.veiculoMarca }}
        </span>
        <span v-if="vistoria?.veiculoModelo"
          >Modelo: {{ vistoria?.veiculoModelo }}</span
        >
        <span>
          Atualizado em:
          {{ dayjs(vistoria?.dateReceived).format('DD/MM/YY HH:mm') }}
        </span>
      </div>

      <!-- Seção: Etapas da Vistoria -->
      <div class="section">
        <span>Etapas da Vistoria</span>
      </div>

      <!-- Etapas da Vistoria -->
      <div
        v-for="(element, index) in etapas"
        :key="index"
        class="resumo__container__item"
      >
        <!-- Nome da Posição da Foto e Icone de Status -->
        <div class="resumo__container__item__icone-status">
          <span class="title">
            {{ element.nomeEtapa }}
          </span>
          <div>
            <!-- Etapa Sicronizada -->
            <IconCloud
              class="synced"
              @click="showHideModalSynced()"
              v-if="element.sync && !element?.redo"
            />
            <!-- Etapa Aguardando sicronização -->
            <IconExclamation
              class="sync"
              @click="showHideModalSync()"
              v-if="!element.sync && element!.prepared"
            />
          </div>
        </div>

        <!-- Opcional -->
        <span v-if="!element.obrigatorio" class="texto"> Opcional </span>

        <!-- Files -->
        <ItemResumoFile
          :key="index"
          v-if="isFoto(element.tipo)"
          :item="element"
          @retry="retry($event)"
        />

        <!-- Termos -->
        <ItemResumoTermo
          :key="index"
          v-if="element.tipo === TIPO_ETAPA_TERMO"
          :item="element"
          @retry="retry($event)"
        />

        <!-- Dados: Pessoais, Endereço, CNH e Veículo -->
        <ItemResumoDados
          :key="index"
          v-if="isResumo(element.tipo)"
          :item="element"
          @retry="retry($event)"
        />
      </div>

      <!-- Botões de Ações -->
      <div class="resumo__container__actions">
        <ButtonTakePhoto2 :foto-adicional="true" />

        <div class="btn-vistoria" v-if="userMediaPermission">
          <button class="btn" @click="addVideoAdicional()">
            Incluir Vídeo Adiconal
          </button>
        </div>

        <ButtonTakeVideo2 :foto-adicional="true" v-else />
      </div>
    </div>

    <FooterSync model="primary" @go-envio="goEnvio()" />
  </div>
</template>

<style scoped lang="scss">
.resumo {
  &__container {
    margin-top: 1rem;
    margin-bottom: 7rem;

    &__title-dados-proposta {
      display: flex;
      justify-content: space-between;

      svg {
        display: block;
        width: 1.2rem;
        height: 1.2rem;
        cursor: pointer;
      }
    }

    &__dados-proposta {
      font-size: 0.6rem;
      margin-bottom: 0.8rem;

      span {
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    &__item {
      border-bottom: 1px solid #ccc;
      margin-bottom: 1rem;

      &__icone-status {
        display: flex;
        align-items: center;
        justify-content: space-between;

        svg {
          display: block;
          width: 1.2rem;
          height: 1.2rem;
          cursor: pointer;
        }

        .synced {
          color: var(--success);
        }

        .sync {
          color: var(--alert);
        }
      }
    }

    &__actions {
      .btn-vistoria {
        margin-top: 0.8rem;
      }
    }
  }
}
</style>
