import { v4 as uuidv4 } from 'uuid';

// Model
import type { Vistoria } from '@/model/vistoria.interface';
import type {
  VistoriaEtapaDadosFinanceiros,
  VistoriaEtapas,
} from '@/model/vistoria-etapas.interface';

// Service
import { db } from '../db';
import { updateVistoriaEtapasStore } from '@/service/store';

// Other
import {
  STATUS_PERMITE_EDICAO_DADOS,
  TIPO_ETAPA_DADOS_FINANCEIROS,
} from '@/global';

/**
 * Recupera a Etapa Dados Fincaneiros para salvar no IndexedDB
 * @param vistoria dados da vistoria
 * @param ordem ordem da vistoria
 */
export function getVistoriaEtapaDadosFinanceirosSaveIndexedDB(
  vistoria: Vistoria,
  ordem: number,
) {
  const idStatus = vistoria.id_Status;

  if (!STATUS_PERMITE_EDICAO_DADOS.includes(idStatus)) {
    return null;
  }

  const chave = vistoria.chave;
  const sync = vistoria.validatedDadosFinanceiros;
  const dadosFinanceiros = vistoria.dadosFinanceiros;

  dadosFinanceiros.validatedForm = vistoria.validatedDadosFinanceiros;

  return {
    idEtapa: uuidv4(),
    chave,
    ordem,
    nomeEtapa: 'Dados Financeiros',
    obrigatorio: true,
    tipo: TIPO_ETAPA_DADOS_FINANCEIROS,
    sync,
    dadosFinanceiros,
  } as VistoriaEtapas;
}

/**
 * Salvar os dados financeiros
 * @param chave chave da proposta/vistoria
 * @param idEtapa id da etapa da vistoria
 * @param dados dados Finaneiros
 */
export async function saveDadosFinanceiros(
  chave: string,
  idEtapa: string,
  dados: VistoriaEtapaDadosFinanceiros,
) {
  const vistoriaEtapas = await db.vistoriaEtapas
    .where('[idEtapa+chave]')
    .equals([idEtapa, chave])
    .first();

  if (vistoriaEtapas) {
    vistoriaEtapas!.dadosFinanceiros = dados;
    vistoriaEtapas!.prepared = true;
    vistoriaEtapas!.sync = false;

    await db.vistoriaEtapas.update([idEtapa, chave], vistoriaEtapas);

    await updateVistoriaEtapasStore(chave);
  }
}

/**
 * Recupera a Etapa Dados financeiros não sincronizada
 * @param chave da proposta/vistoria
 */
export async function getVistoriaEtapaDadosFinanceirosNotSync(chave: string) {
  const vistoriaEtapas = await db.vistoriaEtapas
    .where('chave')
    .equals(chave)
    .toArray();

  const vistoriaEtapa = vistoriaEtapas.find(
    (x) => x.tipo === 'DADOS_FINANCEIROS',
  );

  if (vistoriaEtapa) {
    const result = vistoriaEtapa?.dadosFinanceiros || null;

    if (!result) {
      return result;
    }

    if (!vistoriaEtapa.sync && vistoriaEtapa.prepared) {
      return vistoriaEtapa;
    }
  }

  return null;
}
