//#region Import

import { shallowRef } from 'vue';

// Component
import IconCamera from '@/shared/icons/IconCamera.vue';
import IconMapPin from '@/shared/icons/IconMapPin.vue';

// Other
import { MEDIA_STREAM_CONSTRAINTS_VIDEO } from './../../global';

//#endregion

/**
 * Solicita a permissão da câmera
 */
const promptPermissionsCamera = async (element: CheckListPermissions) => {
  element.processing = true;

  try {
    const cameraStream = await navigator.mediaDevices.getUserMedia(
      MEDIA_STREAM_CONSTRAINTS_VIDEO,
    );

    element.allowPermission = true;

    // Encerra a câmera
    const tracks = cameraStream.getTracks();

    tracks.forEach((track: any) => {
      track.stop();
    });
  } catch (error) {
    element.allowPermission = false;
    console.error(error);
  } finally {
    element.checkPermission = false;
    element.processing = false;
  }
};

/**
 * Solicita a permissão da Localização
 */
const promptPermissionsGPS = (element: CheckListPermissions) => {
  return new Promise<void>((resolve) => {
    element.processing = true;

    const processingFinish = () => {
      element.checkPermission = false;
      element.processing = false;
      resolve();
    };

    navigator.geolocation.getCurrentPosition(
      () => {
        element.allowPermission = true;
        processingFinish();
      },
      (error) => {
        console.error(error);
        element.allowPermission = false;
        processingFinish();
      },
    );
  });
};

export interface CheckListPermissions {
  title: string;
  label: string;
  labelSuccess: string;
  labelFail: string;
  iconComponent: any;
  functionName: Function;
  checkPermission: boolean;
  allowPermission: boolean | null;
  processing: boolean;
  permissions: string;
}

export const CHECK_LIST_PERMISSIONS: CheckListPermissions[] = [
  {
    title: 'Sua Câmera',
    label: 'Usaremos a câmera para tirar a fotos da vistoria',
    labelSuccess: 'Tudo certo, com a sua câmera!',
    labelFail: 'Acesso a câmera negada!',
    iconComponent: shallowRef(IconCamera),
    functionName: promptPermissionsCamera,
    checkPermission: true,
    allowPermission: null,
    processing: false,
    permissions: 'userMedia',
  },
  {
    title: 'Sua Localização',
    label: 'Usaremos para identificar a localização da vistoria',
    labelSuccess: 'Tudo certo, com a localização!',
    labelFail: 'Acesso a localização negada!',
    iconComponent: shallowRef(IconMapPin),
    functionName: promptPermissionsGPS,
    checkPermission: true,
    allowPermission: null,
    processing: false,
    permissions: 'currentPosition',
  },
];
