<script lang="ts" setup>
//#region Imports
import { computed, onMounted, ref, watch } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

// Component
import IconCheck from '@/shared/icons/IconCheck.vue';
import Loading from '@/shared/component/show-loading.vue';

// Model
import type { VistoriaEtapaDadosFinanceiros } from '@/model/vistoria-etapas.interface';
import type { Formas } from '@/model/vistoria.interface';

// Service
import { useStepVistoria } from '@/stores/stepVistoria';
import { saveDadosFinanceiros } from '@/service/vistoria-etapas/dados-financeiros';

//#endregion

const loading = ref<boolean>(true);

const vistoriaStore = useStepVistoria();
let listTiposPagamentos = ref<Formas[]>(
  vistoriaStore.vistoria!.formasPagamento.formas,
);
let listParcelas = ref<number[]>();
const listDiaVencimento = ref<number[]>();

const state = ref<VistoriaEtapaDadosFinanceiros>({
  idProposta: 0,
  idTipoPagamento: null,
  parcelas: null,
  diaVencimentoBoleto: null,
  validatedForm: false,
});

const validationRules = computed(() => {
  const baseValidation = {
    idProposta: 0,
    idTipoPagamento: { required },
    parcelas: { required },
    diaVencimentoBoleto: { required },
  };

  return baseValidation;
});

const v$ = useVuelidate(validationRules, state, { $autoDirty: true });

watch(
  () => state.value.idTipoPagamento,
  () => getListParcelas(),
);

const getListParcelas = () => {
  if (state.value.idTipoPagamento) {
    const formaSelecionada = listTiposPagamentos.value.find(
      (t) => t.idTipoPagamento === state.value.idTipoPagamento,
    );

    if (formaSelecionada) {
      listParcelas.value = Array.from(
        { length: formaSelecionada.parcelas },
        (_, index) => index + 1,
      );
    }
  }
};

/**
 * Recupera a lista de dias de vencimento
 * @param value lista de dias configuradas na empresa
 */
const getListDiaVencimento = (value: string) => {
  const dayCurrentDate = new Date().getDate();

  const arrayVencimento = value
    .split(',')
    .map(Number)
    .sort((a, b) => a - b);

  const newListDiaVencimento = arrayVencimento.filter(
    (element) => element <= dayCurrentDate + 4 && dayCurrentDate - element <= 5,
  );

  if (dayCurrentDate >= 16) newListDiaVencimento.push(10);
  if (dayCurrentDate >= 21) newListDiaVencimento.push(15);
  if (dayCurrentDate >= 26) newListDiaVencimento.push(20);

  listDiaVencimento.value = newListDiaVencimento.sort((a, b) => a - b);
};

/**
 * Recupera os dados preenchidos anteriormente
 */
const getPreviousData = () => {
  const dadosFinaneiros = vistoriaStore.currentVistoriaEtapa?.dadosFinanceiros;
  if (dadosFinaneiros) {
    state.value = { ...dadosFinaneiros };
  }

  state.value.idProposta = vistoriaStore.vistoria!.idProposta;

  getListParcelas();
  getListDiaVencimento(vistoriaStore.vistoria!.diasVencimento);
};

onMounted(async () => {
  getPreviousData();

  // Tempo para atualizar front
  setTimeout(() => {
    loading.value = false;
  }, 300);
});

/**
 * Avança para o próximo etapa
 */
const nextStep = async () => {
  const chave = vistoriaStore!.vistoria!.chave;
  const idEtapa = vistoriaStore!.currentVistoriaEtapa!.idEtapa;
  const dados: VistoriaEtapaDadosFinanceiros = { ...state.value };

  const validForm = await v$.value.$validate();

  if (validForm) {
    dados.validatedForm = true;
    await saveDadosFinanceiros(chave, idEtapa, dados);
    await vistoriaStore.nextVistoriaEtapa();
  }
};
</script>

<template>
  <Loading :show="loading" />

  <div class="container cnh__container">
    <span class="title">Dados Financeiros</span>

    <span class="texto">Preencha dados financeiros da sua proposta</span>

    <div class="cnh__container__form">
      <!-- Forma de Pagamento -->
      <div
        class="form-group"
        :class="{ 'has-error': v$.idTipoPagamento.$errors.length }"
      >
        <label class="form-label label-sm">Forma de Pagamento:*</label>
        <select class="form-select" v-model="state.idTipoPagamento">
          <option
            v-for="(item, index) in listTiposPagamentos"
            :key="index"
            :value="item.idTipoPagamento"
          >
            {{ item.descricao }}
          </option>
        </select>
        <p class="form-input-hint">Forma de pagamento inválida</p>
      </div>

      <!-- Quantidade de Parcelas -->
      <div
        v-if="state.idTipoPagamento"
        class="form-group"
        :class="{ 'has-error': v$.parcelas.$errors.length }"
      >
        <label class="form-label label-sm">Quantidade de Parcelas:*</label>
        <select class="form-select" v-model="state.parcelas">
          <option v-for="(item, index) in listParcelas" :key="index">
            {{ item }}
          </option>
        </select>
        <p class="form-input-hint">Quantidade de parcelas inválido</p>
      </div>

      <!-- Dia de Vencimento -->
      <div
        class="form-group"
        :class="{ 'has-error': v$.diaVencimentoBoleto.$errors.length }"
      >
        <label class="form-label label-sm">Dia de Vencimento do Boleto:*</label>
        <select class="form-select" v-model="state.diaVencimentoBoleto">
          <option v-for="(item, index) in listDiaVencimento" :key="index">
            {{ item }}
          </option>
        </select>
        <p class="form-input-hint">Dia de vencimento do boletoinválido</p>
      </div>
    </div>

    <div class="btn-vistoria container__next">
      <button
        class="btn btn-primary"
        @click="nextStep()"
        :disabled="v$.$invalid"
      >
        <IconCheck />
        <span>Salvar Dados</span>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cnh__container {
  margin: 0.8rem auto;

  span {
    display: block;
  }

  &__form {
    margin-top: 0.8rem;
    margin-bottom: 1.6rem;

    .form-input-hint {
      display: none;
    }

    .has-error {
      .form-input-hint {
        display: block;
      }
      p.form-input-hint {
        margin-bottom: 0;
      }
    }

    .divider {
      margin-top: 1.2rem;
    }
  }
}
</style>
