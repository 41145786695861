<script lang="ts" setup>
//#region Imports

import { computed, onMounted, ref } from 'vue';
import { requiredUnless } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { vMaska } from 'maska';

// Model
import type { VistoriaEtapaDadosCnh } from '@/model/vistoria-etapas.interface';

// Service
import { saveDadosCNH } from '@/service/vistoria-etapas/dados-cnh';
import { useStepVistoria } from '@/stores/stepVistoria';

// Component
import IconCheck from '@/shared/icons/IconCheck.vue';
import Loading from '@/shared/component/show-loading.vue';

// Other
import { LIST_TIPO_CARTEIRA } from '@/global';
import {
  validateCNH,
  validateCnhVencidade,
  validateDate,
} from '@/shared/validate';

//#endregion

const vistoriaStore = useStepVistoria();
const loading = ref<boolean>(true);
const legendaObrigatorio = ref<string>();

const state = ref<VistoriaEtapaDadosCnh>({
  idProspect: 0,
  naoPossuiCnh: false,
  validatedForm: false,
});

const validationRules = computed(() => {
  let localRules;

  if (!state.value.naoPossuiCnh) {
    localRules = {
      cnh: {
        required: requiredUnless(() => state.value.naoPossuiCnh),
        validateCNH,
      },
      catCnh: { required: requiredUnless(() => state.value.naoPossuiCnh) },
      dataVencimentoCnh: {
        required: requiredUnless(() => state.value.naoPossuiCnh),
        validateDate,
        validateCnhVencidade,
      },
    };
  }

  return localRules;
});

const v$ = useVuelidate(validationRules, state, { $autoDirty: true });

/**
 * Sanitiza os dados conforme parâmetro 'naoPossuiCnh'
 */
const sanitize = () => {
  legendaObrigatorio.value = !state.value.naoPossuiCnh ? '*' : '';

  if (state.value.naoPossuiCnh) {
    state.value.cnh = null;
    state.value.catCnh = null;
    state.value.dataVencimentoCnh = null;
  }
};

/**
 * Avança para o próximo etapa
 */
const nextStep = async () => {
  const chave = vistoriaStore!.vistoria!.chave;
  const idEtapa = vistoriaStore!.currentVistoriaEtapa!.idEtapa;
  const dados: VistoriaEtapaDadosCnh = Object.assign({}, state.value);

  const validForm = await v$.value.$validate();

  if (dados.naoPossuiCnh || validForm) {
    dados.validatedForm = true;
    await saveDadosCNH(chave, idEtapa, dados);
    await vistoriaStore.nextVistoriaEtapa();
  }
};

/**
 * Recupera os dados preenchidos anteriormente
 */
const getPreviousData = () => {
  const dadosCNH = vistoriaStore.currentVistoriaEtapa?.dadosCNH;
  if (dadosCNH) {
    state.value = Object.assign({}, dadosCNH);
  }

  state.value.idProspect = vistoriaStore.vistoria!.idProspect;
};

onMounted(async () => {
  getPreviousData();

  // Tempo para atualizar front
  setTimeout(() => {
    loading.value = false;
  }, 300);
});
</script>

<template>
  <Loading :show="loading" />

  <div class="container cnh__container">
    <span class="title">Dados da CNH</span>

    <span class="texto">Preencha com os dados da sua CNH</span>

    <div class="cnh__container__form">
      <!-- Número da CNH -->
      <div class="form-group" :class="{ 'has-error': v$.cnh?.$errors.length }">
        <label class="form-label label-sm" for="input-cnh"
          >Número da CNH:{{ legendaObrigatorio }}</label
        >
        <input
          class="form-input"
          type="tel"
          id="input-cnh"
          placeholder="Infomre o número da sua CNH"
          v-maska
          data-maska="############"
          v-model="state.cnh"
        />
        <p class="form-input-hint">Número da CNH inválido</p>
      </div>

      <!-- Data de Validade CNH -->
      <div
        class="form-group"
        :class="{ 'has-error': v$.dataVencimentoCnh?.$errors.length }"
      >
        <label class="form-label label-sm" for="input-datavencimento"
          >Data de Validade da CNH:{{ legendaObrigatorio }}</label
        >
        <input
          class="form-input"
          type="tel"
          id="input-datavencimento"
          placeholder="Informa a data de vencimento"
          v-maska
          data-maska="##/##/####"
          v-model="state.dataVencimentoCnh"
        />
        <p class="form-input-hint">Data inválida ou CNH vencida</p>
      </div>

      <!-- Categoria da CNH -->
      <div
        class="form-group"
        :class="{ 'has-error': v$.catCnh?.$errors.length }"
      >
        <label class="form-label label-sm"
          >Tipo da CNH:{{ legendaObrigatorio }}</label
        >
        <select class="form-select" v-model="state.catCnh">
          <option v-for="(tipo, index) in LIST_TIPO_CARTEIRA" :key="index">
            {{ tipo }}
          </option>
        </select>
        <p class="form-input-hint">Selecione o tipo da CNH</p>
      </div>

      <div class="divider text-center" data-content="OU"></div>

      <!-- Não Possui CNH -->
      <div class="form-group">
        <label class="form-checkbox texto">
          <input
            type="checkbox"
            v-model="state.naoPossuiCnh"
            :change="sanitize()"
          />
          <i class="form-icon"></i> Não possui CNH
        </label>
      </div>
    </div>

    <div class="btn-vistoria container__next">
      <button
        class="btn btn-primary"
        @click="nextStep()"
        :disabled="
          !((!state.naoPossuiCnh && !v$.$invalid) || state.naoPossuiCnh)
        "
      >
        <IconCheck />
        <span>Salvar Dados</span>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cnh__container {
  margin: 0.8rem auto;

  span {
    display: block;
  }

  &__form {
    margin-top: 0.8rem;
    margin-bottom: 1.6rem;

    .form-input-hint {
      display: none;
    }

    .has-error {
      .form-input-hint {
        display: block;
      }
      p.form-input-hint {
        margin-bottom: 0;
      }
    }

    .divider {
      margin-top: 1.2rem;
    }
  }
}
</style>
