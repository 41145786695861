<script setup lang="ts">
//#region Import

import { onMounted, ref } from 'vue';

// Component
import StatusDone from '@/shared/component/status-done.vue';
import FooterApp from '@/shared/component/footer-app.vue';

// Service
import { useStepVistoria } from '@/stores/stepVistoria';

// Other
import { STATUS_PROPOSTA_FINALIZADA } from '@/global';

//#endregion

const vistoriaStore = useStepVistoria();
const vistoriaAprovada = ref<boolean>(false);

onMounted(() => {
  vistoriaAprovada.value =
    vistoriaStore.vistoria?.id_Status === STATUS_PROPOSTA_FINALIZADA;
});
</script>

<template>
  <div class="vistoria-aprovada" v-if="vistoriaAprovada">
    <div class="vistoria-aprovada__status">
      <StatusDone />
      <span class="title"> Vistoria Aprovada </span>
    </div>

    <FooterApp />
  </div>
</template>

<style lang="scss">
.vistoria-aprovada {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  &__status {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      margin-top: 0.8rem;
    }
  }
}
</style>
